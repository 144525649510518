import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

/*
**  Import all vendor libraries
*/

import { createMetaManager } from 'vue-meta';

/*
**  Create the app
*/

const app = createApp(App);

/*
**  Vue use statements
*/

app.use(createMetaManager());
app.use(router);

/*
**  Import additional styles
*/

import '@/assets/styles/foundation.css'
import '@/assets/styles/animated.css'
import '@/styles/app.scss'

/*
**  Mount the app
*/

app.mount('#app');
