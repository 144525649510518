import { createRouter, createWebHistory } from "vue-router";

/*
** Set the scroll behavior
*/

const scrollBehavior = (to) => {

    // Check if nav element is a hashtag
    if (to.hash) {

        return { selector: to.hash };

    }

    return { x: 0, y: 0 };

};

/*
** Set the routes
*/

const routes = [

    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home/Index.vue')
    },

];

const router = createRouter({

    /*
    ** Remove hashtag from URL
    */

    history: createWebHistory(),

    /*
    ** Set the scroll behavior
    */

    scrollBehavior,

    /*
    ** Set the base URL
    */

    base: process.env.BASE_URL,

    /*
    ** Set the routes
    */

    routes

});

/*
** Export the object
*/

export default router;