<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content}` : `Avail` }}</template>
        <template v-slot:description="{ content }">{{ content ? `${content}` : `Workflow Infrastructure for the Supply Chain` }}</template>
    </metainfo>
  <RouterView />
</template>

<script>
import { useMeta } from 'vue-meta';

export default {

    name: 'App',
    setup () {

        useMeta({

            title: '',
            description: '',
            og: { 
                title: 'Avail | Workflow Infrastructure for the Supply Chain', 
                description: '', 
                image: '', 
                url: '' 
            },
            twitter: { 
                site: '', 
                card: '', 
                title: 'Avail | Workflow Infrastructure for the Supply Chain', 
                description: '', 
                image: '' 
            },
            htmlAttrs: { 
                lang: 'en-us', 
                amp: true 
            }

        });

    }

}
</script>
